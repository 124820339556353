@media (min-width: 320px) and (max-width: 576px) {
  .printed-detail-container {
    height: 16em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .printed-image {
      height: 100%;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-image-main {
        height: 60%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .printed-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 15%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 30%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .printed-detail {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-info {
        height: fit-content;
        width: 100%;

        .printed-name {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .printed-price {
          font-size: 8px;
          display: flex;

          .price-number {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .printed-describe {
          height: fit-content;
          font-size: 8px;

          p {
            margin: 0;
          }
        }
      }
    }

    .popup-form {
      height: 10%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 5%;
      font-size: 8px;

      .request-form {
        width: 60%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 8px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .printed-detail-container {
    height: 16em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .printed-image {
      height: 100%;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-image-main {
        height: 60%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .printed-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 15%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 30%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .printed-detail {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-info {
        height: fit-content;
        width: 100%;

        .printed-name {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .printed-price {
          font-size: 8px;
          display: flex;

          .price-number {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .printed-describe {
          height: fit-content;
          font-size: 8px;

          p {
            margin: 0;
          }
        }
      }
    }

    .popup-form {
      height: 10%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 5%;
      font-size: 8px;

      .request-form {
        width: 60%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 8px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .printed-detail-container {
    height: 20em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .printed-image {
      height: 100%;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-image-main {
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .printed-image-option {
        display: flex;
        justify-content: center;
        gap: 5%;
        height: 20%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 30%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .printed-detail {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .printed-info {
        height: fit-content;
        width: 100%;

        .printed-name {
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .printed-price {
          font-size: 12px;
          display: flex;

          .price-number {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .printed-describe {
          height: fit-content;
          font-size: 12px;

          p {
            margin: 0;
          }
        }
      }

      .popup-form {
        height: 10%;
        width: 100%;

        PopupForm {
          width: 20%;
          height: 100%;
        }
      }

      .request-contact {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        gap: 5%;
        font-size: 12px;

        .request-form {
          width: 60%;

          input {
            width: 100%;
            height: 100%;
          }
        }

        .request-button {
          height: 100%;
          width: 20%;
          padding: 1%;
          outline: none;
          border-radius: 12px;
          border: none;
          background-color: #354b9c;
          color: white;
          font-size: 12px;
          &:hover {
            opacity: 0.9;
            transition: all ease 0.5s;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .printed-detail-container {
    height: 40em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .printed-image {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 5%;

      .printed-image-main {
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .printed-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 20%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 20%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .printed-detail {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5%;

      .printed-info {
        height: 60%;
        width: 100%;

        .printed-name {
          font-size: 25px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .printed-price {
          font-size: 20px;
          display: flex;

          .price-number {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .printed-describe {
          height: fit-content;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5%;

      .request-form {
        height: 100%;
        width: 45%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 16px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}
