#TableManageUser {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#TableManageUser td,
#TableManageUser th {
  border: 1px solid #ddd;
  padding: 8px;

  ul {
    padding: 0 0 0 15px;
  }
}

#TableManageUser tr:nth-child(even) {
  background-color: #f2f2f2;
}

#TableManageUser tr:hover {
  background-color: #ddd;
}

#TableManageUser th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04aa6d;
  text-align: left;
  color: white;
}
