@media (min-width: 320px) and (max-width: 576px) {
  .result-list {
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 8px #ddd;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 2px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1002;
    position: absolute;
    font-size: 6px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .result-list {
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 8px #ddd;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 2px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1002;
    position: absolute;
    font-size: 8px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .result-list {
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 8px #ddd;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 2px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1002;
    position: absolute;
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .result-list {
    width: fit-content;
    background-color: white;
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 0px 8px #ddd;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 2px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 1002;
    position: absolute;
  }
}
