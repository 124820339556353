.login-background {
  height: 100vh;
  width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(34, 193, 195, 1) 0%,
    rgba(253, 187, 45, 1) 100%
  );

  .login-container {
    background-color: white;
    height: 300px;
    width: 400px;
    margin: auto;
    border-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .login-content {
      padding: 10px;

      .text-login {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        padding-top: 10px;
      }

      .login-input {
        margin: 7px 0;

        .custom-input-password {
          i {
            position: auto;
            cursor: pointer;
          }
        }
      }

      .btn-login {
        width: 100%;
        height: 35px;
        margin: 10px 0;
        border-radius: 15px;
        outline: none;
        border: none;
        background: linear-gradient(
          180deg,
          rgba(34, 193, 195, 1) 0%,
          rgba(253, 187, 45, 1) 100%
        );
        color: white;
      }

      .forgot-password {
        font-size: 12px;
      }
    }
  }
}
