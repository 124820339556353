@media (min-width: 320px) and (max-width: 576px) {
  .input-wrapper {
    background-color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    height: 1rem;
    padding: 0 5px;
    // box-shadow      : 0px 3px 8px #ddd;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      border: none;
      height: 100%;
      font-size: 6px;
      font-family: Arial, Helvetica, sans-serif;
      width: 100%;
      margin-left: 5px;

      &:focus {
        outline: none;
      }
    }

    .fas.fa-search {
      height: fit-content;
      padding-right: 10px;
      color: #354b9c;
      border-right: 1px solid black;
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .input-wrapper {
    background-color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    height: 1.5rem;
    padding: 0 5px;
    // box-shadow      : 0px 3px 8px #ddd;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      border: none;
      height: 100%;
      font-size: 9px;
      font-family: Arial, Helvetica, sans-serif;
      width: 100%;
      margin-left: 5px;

      &:focus {
        outline: none;
      }
    }

    .fas.fa-search {
      height: fit-content;
      padding-right: 10px;
      color: #354b9c;
      border-right: 1px solid black;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .input-wrapper {
    background-color: white;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    height: 2rem;
    padding: 0 5px;
    // box-shadow      : 0px 3px 8px #ddd;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      border: none;
      height: 100%;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      width: 100%;
      margin-left: 5px;

      &:focus {
        outline: none;
      }
    }

    .fas.fa-search {
      height: fit-content;
      padding-right: 10px;
      color: #354b9c;
      border-right: 1px solid black;
    }
  }
}

@media (min-width: 992px) {
  .input-wrapper {
    background-color: white;
    height: 2.5rem;
    width: 100%;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0 15px;
    // box-shadow      : 0px 3px 8px #ddd;
    display: flex;
    align-items: center;

    input {
      background-color: transparent;
      border: none;
      width: 100%;
      height: 100%;
      font-size: 15px;
      font-family: Arial, Helvetica, sans-serif;

      margin-left: 5px;

      &:focus {
        outline: none;
      }
    }

    .fas.fa-search {
      height: fit-content;
      padding-right: 10px;
      color: #354b9c;
      border-right: 1px solid black;
    }
  }
}
