@media (min-width: 320px) and (max-width: 576px) {
  .search-result {
    padding: 5px 10px;
    font-family: Helvetica, sans-serif;
    z-index: 1001;

    &:hover {
      background-color: #354b9c;
    }

    &:active {
      background-color: #354b9c;
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .search-result {
    padding: 5px 10px;
    font-family: Helvetica, sans-serif;
    z-index: 1001;

    &:hover {
      background-color: #354b9c;
    }

    &:active {
      background-color: #354b9c;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .search-result {
    padding: 5px 10px;
    font-family: Helvetica, sans-serif;
    z-index: 1001;

    &:hover {
      background-color: #354b9c;
    }

    &:active {
      background-color: #354b9c;
    }
  }
}

@media (min-width: 992px) {
  .search-result {
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    z-index: 1001;

    &:hover {
      background-color: #354b9c;
    }

    &:active {
      background-color: #354b9c;
    }
  }
}
