.detail-order-container {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  transition: step-start;
  background: rgba(0, 0, 0, 0.5);
  .order-container {
    .order {
      background-color: white;
      .content-left {
        width: 70%;

        .co-logo {
          width: 15%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .content-right {
        width: 30%;
      }

      .export-info {
        .col-9 {
          span {
            font-weight: bold;
            text-transform: uppercase;
          }
        }
      }

      .cust-info {
        position: relative; /* Establish a positioning context for absolute children */
        .col-3 {
          text-align: center;
        }

        .col-9 {
          span {
            font-weight: bold;
            text-transform: uppercase;
          }
        }

        .product-table {
          position: relative; /* Establish a positioning context for absolute children */

          .canceled-text {
            position: absolute; /* Position absolutely within cust-info */
            top: 15%;
            left: 25%;
            transform: translate(-50%, -50%); /* Center using transform */
            z-index: 1; /* Ensure it flows above other content */
            transform: rotate(330deg);

            h1 {
              font-size: 80px;
              border: 1px solid red;
              color: red;
            }
          }
        }
      }
    }
    .amount {
      display: flex;
      align-items: center;

      .decrease,
      .increase {
        border: 1px solid black;
        display: inline-block;
        text-align: center;
        width: fit-content;
        height: fit-content;

        button {
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
          background-color: white;

          &:hover {
            color: #354b9c;
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        text-align: center;
        width: 50px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 5px;
      }
    }
  }
}

.option-admin {
  position: fixed;
  bottom: 10px;
  right: 120px;
  z-index: 1003;

  button {
    font-size: 18px;
  }
}

.sign {
  padding: 0;
  background-color: white;

  p {
    margin: 0;
  }
  .col {
    border: 1px solid black;
  }

  .signspace {
    height: 100px;
  }
}

#TableManageOrder {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #04aa6d;
    text-align: left;
    color: white;
  }
}

@media only print and (max-width: 1200px) {
  @page {
    margin: 0;
    padding: 0;
    size: A4;
  }

  body * {
    visibility: hidden;
  }

  #order-admin,
  #order-admin * {
    Table {
      width: 100%;
    }

    visibility: visible;

    font-size: 17px;

    h5,
    .total-value {
      font-size: 20px;
    }

    .col-6 {
      width: 40%;
    }

    .col-1 {
      width: 10%;
    }

    .col-2 {
      width: 20%;
    }

    .col-9 {
      width: 70%;

      span {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 15px;
      }
    }

    .col-3 {
      width: 30%;
    }
    .col-12 {
      width: 100%;
    }

    .order-header {
      .content-left {
        width: 70%;

        .co-logo {
          width: 15%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .content-right {
        width: 30%;
      }
    }

    .export-info {
      width: 100%;
    }
    .cust-info {
      width: 100%;

      td.blank {
        height: 0;
      }

      .canceled-text {
        position: absolute; /* Position absolutely within cust-info */
        top: 30%;
        left: 18%;
        transform: translate(-50%, -50%); /* Center using transform */
        z-index: 1; /* Ensure it flows above other content */
        transform: rotate(330deg);
        width: fit-content;

        h1 {
          font-size: 80px;
          border: 1px solid red;
          color: red;
        }
      }

      tr.small {
        font-size: 14px;
      }
    }

    .caution {
      font-size: 14px;
    }
    div.text-primary.small {
      font-size: 14px;
    }
  }

  #order-admin {
    width: 95%; /* Adjust as needed */
    height: auto;
  }
}
