@import "../../styles/common.scss";

.header-container {
    z-index         : 99;
    display         : flex;
    justify-content : space-between;
    background-color: $colormain;
    color           : $common-text-color;
    height          : 40px;
    position        : relative;
    align-items     : center;

    .header-tail {
        .welcome {
            margin-right: 10px
        }

        .btn-logout {
            color      : $common-text-color;
            line-height: 40px;
            height     : 40px;
            padding    : 0 10px;

            &:hover {
                background-color: darken($colormain, 5);
            }

            i {
                font-size: $base-size + 1px;
            }
        }
    }
}