@media (min-width: 320px) and (max-width: 576px) {
  .product-detail-container {
    height: 16em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .product-image {
      height: 100%;
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-image-main {
        height: 60%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 15%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 30%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .product-detail {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-info {
        height: fit-content;
        width: 100%;

        .product-name {
          font-size: 10px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-price-container {
          font-size: 8px;
          gap: 20px;

          .product-price {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }

          .stock {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .product-describe {
          height: fit-content;
          font-size: 8px;

          p {
            margin: 0;
          }
        }
      }

      .product-selection {
        width: 100%;
        display: flex;
        height: fit-content;

        .product-option {
          display: flex;
          align-items: center;
          width: 80%;
          gap: 1%;
          font-size: 8px;

          option {
            font-size: 8px;
          }
        }

        .product-amount {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 1%;
          font-size: 8px;

          .amount {
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 5%;
            width: 60%;

            .decrease,
            .increase {
              border: 1px solid black;
              text-align: center;
              width: fit-content;
              font-size: 6px;
              height: fit-content;

              button {
                outline: none;
                border: none;
                background-color: white;

                &:hover {
                  background-color: yellow;
                  color: #354b9c;
                  transition: all 0.5s ease 0s;
                }
              }
            }

            input {
              text-align: center;
              width: 50%;
              height: fit-content;
              font-size: 8px;
              background: #fff;
              border: 1px solid black;
              outline: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .cart-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5px;
      font-size: 8px;

      .button-add-cart {
        width: fit-content;
        height: 100%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;

        .order {
          display: none;
        }
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 5%;
      font-size: 8px;

      .request-form {
        width: 60%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 8px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .product-detail-container {
    height: 16em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .product-image {
      height: 100%;
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-image-main {
        height: 60%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 15%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 30%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .product-detail {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-info {
        height: fit-content;
        width: 100%;

        .product-name {
          font-size: 11px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-price-container {
          font-size: 9px;
          gap: 20px;

          .product-price {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }

          .stock {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .product-describe {
          height: fit-content;
          font-size: 9px;

          p {
            margin: 0;
          }
        }
      }

      .product-selection {
        width: 100%;
        display: flex;
        height: fit-content;

        .product-option {
          display: flex;
          align-items: center;
          width: 80%;
          gap: 1%;
          font-size: 9px;

          option {
            font-size: 9px;
          }
        }

        .product-amount {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 1%;
          font-size: 9px;

          .amount {
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 5%;
            width: 60%;

            .decrease,
            .increase {
              border: 1px solid black;
              text-align: center;
              width: fit-content;
              font-size: 7px;
              height: fit-content;

              button {
                outline: none;
                border: none;
                background-color: white;

                &:hover {
                  background-color: yellow;
                  color: #354b9c;
                  transition: all 0.5s ease 0s;
                }
              }
            }

            input {
              text-align: center;
              width: 50%;
              height: fit-content;
              font-size: 9px;
              background: #fff;
              border: 1px solid black;
              outline: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .cart-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5px;
      font-size: 9px;

      .button-add-cart {
        width: fit-content;
        height: 100%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;

        .order {
          display: none;
        }
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 5%;
      font-size: 9px;

      .request-form {
        width: 60%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 9px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-detail-container {
    height: 20em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .product-image {
      height: 100%;
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-image-main {
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-image-option {
        display: flex;
        justify-content: center;
        gap: 0.1%;
        height: 20%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 33.3%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .product-detail {
      width: 65%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .product-info {
        height: fit-content;
        width: 100%;

        .product-name {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-price-container {
          font-size: 10px;
          gap: 20px;

          .product-price {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }

          .stock {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .product-describe {
          height: fit-content;
          font-size: 10px;

          p {
            margin: 0;
          }
        }
      }

      .product-selection {
        width: 100%;
        display: flex;
        height: fit-content;

        .product-option {
          display: flex;
          align-items: center;
          width: 80%;
          gap: 1%;
          font-size: 10px;

          option {
            font-size: 10px;
          }
        }

        .product-amount {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 1%;
          font-size: 10px;

          .amount {
            display: flex;
            align-items: center;
            width: fit-content;
            gap: 5%;
            width: 60%;

            .decrease,
            .increase {
              border: 1px solid black;
              text-align: center;
              width: fit-content;
              font-size: 6px;
              height: fit-content;

              button {
                outline: none;
                border: none;
                background-color: white;

                &:hover {
                  background-color: yellow;
                  color: #354b9c;
                  transition: all 0.5s ease 0s;
                }
              }
            }

            input {
              text-align: center;
              width: 50%;
              height: fit-content;
              font-size: 10px;
              background: #fff;
              border: 1px solid black;
              outline: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .cart-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5px;
      font-size: 10px;

      .button-add-cart {
        width: fit-content;
        height: 100%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        display: flex;
        align-items: center;

        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      gap: 5%;
      font-size: 8px;

      .request-form {
        width: 60%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 8px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-detail-container {
    height: 40em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .product-image {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 5%;

      .product-image-main {
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 20%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 20%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .product-detail {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5%;

      .product-info {
        height: 60%;
        width: 100%;

        .product-name {
          font-size: 25px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-price-container {
          font-size: 20px;
          display: flex;
          gap: 50px;

          .product-price {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }

          .stock {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .product-describe {
          height: fit-content;
          p {
            margin: 0;
          }
        }
      }

      .product-selection {
        width: 100%;
        display: flex;
        height: 10%;

        .product-option {
          display: flex;
          align-items: center;
          width: 33%;
          gap: 1%;
          select {
            padding: 2%;
          }
        }

        .product-amount {
          width: 40%;
          display: flex;
          align-items: center;
          gap: 1%;

          .amount {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 2%;
            .decrease,
            .increase {
              border: 1px solid black;
              text-align: center;
              width: 15%;
              height: 60%;

              button {
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                background-color: white;

                &:hover {
                  background-color: yellow;
                  color: #354b9c;
                  transition: all 0.5s ease 0s;
                }
              }
            }

            input {
              text-align: center;
              width: 30%;
              height: 60%;
              padding: 2%;
              background: #fff;
              border: 1px solid black;
              outline: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .cart-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5%;

      .button-add-cart {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: fit-content;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;

        .order {
          font-size: 16px;
        }
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5%;

      .request-form {
        height: 100%;
        width: 45%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 16px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .contact-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      text-align: center;
      transition: step-start;
    }
  }
}

@media (min-width: 1200px) {
  .product-detail-container {
    height: 40em;
    margin: 2% 4%;
    display: flex;
    font-family: serif;
    gap: 1%;

    .product-image {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 5%;

      .product-image-main {
        height: 75%;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .product-image-option {
        display: flex;
        justify-content: center;
        gap: 1%;
        height: 20%;
        width: 100%;

        .option-image {
          border: 1px solid black;
          width: 20%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .product-detail {
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 5%;

      .product-info {
        height: 60%;
        width: 100%;

        .product-name {
          font-size: 25px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-price-container {
          font-size: 20px;
          display: flex;
          gap: 50px;

          .product-price {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }

          .stock {
            margin-right: 5px;
            color: red;
            font-weight: bold;
          }
        }

        .product-describe {
          height: fit-content;
          p {
            margin: 0;
          }
        }
      }

      .product-selection {
        width: 100%;
        display: flex;
        height: 10%;

        .product-option {
          display: flex;
          align-items: center;
          width: 33%;
          gap: 1%;
          select {
            padding: 2%;
          }
        }

        .product-amount {
          width: 40%;
          display: flex;
          align-items: center;
          gap: 1%;

          .amount {
            display: flex;
            align-items: center;
            height: 100%;
            gap: 2%;
            .decrease,
            .increase {
              border: 1px solid black;
              text-align: center;
              width: 15%;
              height: 60%;

              button {
                height: 100%;
                width: 100%;
                outline: none;
                border: none;
                background-color: white;

                &:hover {
                  background-color: yellow;
                  color: #354b9c;
                  transition: all 0.5s ease 0s;
                }
              }
            }

            input {
              text-align: center;
              width: 30%;
              height: 60%;
              padding: 2%;
              background: #fff;
              border: 1px solid black;
              outline: none;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }

    .cart-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5%;

      .button-add-cart {
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: fit-content;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;

        .order {
          font-size: 16px;
        }
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .popup-form {
      height: 8%;
      width: 100%;

      PopupForm {
        width: 20%;
        height: 100%;
      }
    }

    .request-contact {
      width: 100%;
      height: 8%;
      display: flex;
      gap: 5%;

      .request-form {
        height: 100%;
        width: 45%;

        input {
          width: 100%;
          height: 100%;
        }
      }

      .request-button {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        padding: 1%;
        outline: none;
        border-radius: 10px;
        border: none;
        background-color: #354b9c;
        color: white;
        font-size: 16px;
        &:hover {
          opacity: 0.9;
          transition: all ease 0.5s;
        }
      }
    }

    .contact-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1000;
      text-align: center;
      transition: step-start;
    }
  }
}
