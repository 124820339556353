.news-content-container {
    h2,h3,h4,h5{
        text-align: center;
    }

    h4{
        color: #354b9c;
    }

    h2{
        color: red;
    }
}

@media (min-width: 320px) and (max-width: 576px) {
  .news-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80%;
      height: 200px;
      margin-bottom: 5px;
      display: block;
      margin: auto;
    }
    
    h2,h3,h4,h5{
        text-align: center;
    }
    
    h2,h3{
        font-size: 12px;
    }
    
    h4{
        font-size: 11px;
    }

    h5 {
      font-size: 10px;
    }

    h6 {
      font-size: 8px;
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .news-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 80%;
      height: 200px;
      margin-bottom: 5px;
      display: block;
      margin: auto;
    }
    
    h2,h3,h4,h5{
        text-align: center;
    }
    
    h2,h3{
        font-size: 14px;
    }
    
    h4{
        font-size: 12px;
    }

    h5 {
      font-size: 10px;
    }

    h6 {
      font-size: 8px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-content-container {
    display: flex;
    flex-direction: column; 
    align-items: center;
    img {
      width: 80%;
      height: 250px;
      margin-bottom: 5px;
      display: block;
      margin: auto;
    }
    
    h2,h3,h4,h5{
        text-align: center;
    }
    
    h2,h3{
        font-size: 16px;
    }
    
    h4{
        font-size: 15px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }
  }
}

@media (min-width: 992px) {
  .news-content-container {
    img {
      max-width: 70%;
      max-height: 90%;
      display: block;
      margin: auto;
    }
    
    h2,h3,h4,h5{
        text-align: center;
    }
  }
}
