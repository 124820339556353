.preview-img-container {
    .label-upload {
        padding         : 5px;
        border-radius   : 3px;
        background-color: #ddd;
        cursor          : pointer;

        &:hover {
            opacity: 0.9;
        }
    }

    .preview-img {
        width          : 100%;
        height         : 50px;
        border         : 1px solid black;
        background     : center center no-repeat;
        background-size: contain;
        cursor         : pointer;
    }
}