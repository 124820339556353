@media (min-width: 320px) and (max-width: 576px) {
  .product-page {
    width: 100%;
    height: fit-content;
    padding: 0 10px;
    border: 1px solid black;

    .product-content {
      height: fit-content;

      .product-header {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-size: 12px;
        margin: 5px 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      .product-type-above,
      .product-type-below {
        width: 100%;
        height: 30px;
        margin: 5px 0;
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-align: center;
        gap: 5px;

        .type-change {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 20px;
          font-size: 8px;
          padding: 5px;

          &:hover {
            cursor: pointer;
            background: yellow;
            color: blue;
          }
        }

        .type-change-active {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          font-size: 8px;
          font-weight: bold;
          border-radius: 20px;
          background: yellow;
          color: blue;
          padding: 5px;

          &:active {
            cursor: pointer;
          }
        }
      }

      .product-body {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .slide-product {
          flex: 0 0 50%;
          width: 165px;
          height: 150px;
          padding: 0 15px;
          margin-bottom: 100px;

          .prod-img-container {
            border: 1px solid black;
            height: 100%;
            width: 100%;

            img {
              // border: 1px solid black;
              height: 100%;
              width: 100%;
              cursor: pointer;
              size: inherit;
            }
          }

          .product-detail {
            display: flex;
            flex-direction: column;
            .product-name {
              text-transform: uppercase;
              font-size: 9px;
              font-weight: 500;
              height: 2em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-name-perforated {
              text-transform: uppercase;
              font-size: 9px;
              font-weight: 500;
              height: 2.9em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-price {
              font-size: 8px;
            }
            .product-price-container {
              display: flex;
              flex-direction: column;
              font-size: 8px;
              .price {
                display: flex;

                .product-calculate-type {
                  margin-left: 5px;
                }
              }
            }

            .buttons {
              display: flex;
              flex-direction: column;
              gap: 1px;

              .add-cart-button {
                width: 100%;
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;
                font-size: 8px;

                span.add-cart {
                  display: none;
                }

                &:active {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }

            .request-contact {
              display: flex;
              flex-direction: column;
              .request-form {
                font-size: 8px;
              }
              .request-button {
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;
                font-size: 8px;

                &:active {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .product-page {
    width: 100%;
    height: fit-content;
    padding: 0 10px;
    border: 1px solid black;

    .product-content {
      height: fit-content;

      .product-header {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-size: 14px;
        margin: 5px 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      .product-type-above,
      .product-type-below {
        width: 100%;
        height: 30px;
        margin: 5px 0;
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        text-align: center;
        gap: 5px;

        .type-change {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 20px;
          font-size: 10px;
          padding: 5px;

          &:hover {
            cursor: pointer;
            background: yellow;
            color: blue;
          }
        }

        .type-change-active {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          font-size: 10px;
          font-weight: bold;
          border-radius: 20px;
          background: yellow;
          color: blue;
          padding: 5px;

          &:active {
            cursor: pointer;
          }
        }
      }

      .product-body {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .slide-product {
          flex: 0 0 50%;
          width: 165px;
          height: 155px;
          padding: 0 15px;
          margin-bottom: 80px;

          .prod-img-container {
            border: 1px solid black;
            height: 100%;
            width: 100%;

            img {
              // border: 1px solid black;
              height: 100%;
              width: 100%;
              cursor: pointer;
              size: inherit;
            }
          }

          .product-detail {
            display: flex;
            flex-direction: column;
            .product-name {
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              height: 2em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-name-perforated {
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              height: 2.9em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-price {
              font-size: 9px;
            }
            .product-price-container {
              display: flex;
              font-size: 9px;
              .price {
                display: flex;

                .product-calculate-type {
                  margin-left: 5px;
                }
              }
            }

            .buttons {
              display: flex;
              flex-direction: column;
              gap: 1px;

              .add-cart-button {
                width: 100%;
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;
                font-size: 9px;

                span.add-cart {
                  display: none;
                }

                &:active {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }

            .request-contact {
              display: flex;
              flex-direction: column;
              .request-form {
                font-size: 9px;
              }
              .request-button {
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;
                font-size: 9px;

                &:active {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .product-page {
    width: 100%;
    height: fit-content;
    padding: 0 15px;
    border: 1px solid black;

    .product-content {
      height: fit-content;

      .product-header {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-size: 21px;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      .product-type-above,
      .product-type-below {
        width: 100%;
        height: 35px;
        margin: 10px 0;
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        gap: 10px;

        .type-change {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          font-size: 12px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
            background: yellow;
            color: blue;
            font-size: 15px;
          }
        }

        .type-change-active {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          background: yellow;
          color: blue;
          font-size: 13px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .product-body {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .slide-product {
          flex: 0 0 33.3%;
          width: 215px;
          height: 220px;
          padding: 0 10px;
          margin-bottom: 100px;

          .prod-img-container {
            border: 1px solid black;
            height: 100%;
            width: 100%;

            img {
              // border: 1px solid black;
              height: 100%;
              width: 100%;
              cursor: pointer;
              size: inherit;
            }
          }

          .product-detail {
            height: fit-content;
            .product-name {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-name-perforated {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              height: 3em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-price {
              font-size: 10px;
              display: flex;

              .product-calculate-type {
                margin-left: 5px;
              }
            }

            .price-add-cart {
              font-size: 10px;
              .product-price-container {
                display: flex;

                .product-calculate-type {
                  margin-left: 5px;
                }
              }

              .add-cart-button {
                width: fit-content;
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;

                &:hover {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }

            .request-button {
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .product-page {
    width: 100%;
    height: fit-content;
    padding: 0 20px;
    border: 1px solid black;

    .product-content {
      height: fit-content;

      .product-header {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-size: 25px;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      .product-type-above,
      .product-type-below {
        width: 100%;
        height: 50px;
        margin: 10px 0;
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        gap: 10px;

        .type-change {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          font-size: 16px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
            background: yellow;
            color: blue;
            font-size: 20px;
          }
        }

        .type-change-active {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          background: yellow;
          color: blue;
          font-size: 18px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .product-body {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .slide-product {
          flex: 0 0 25%;
          width: 265px;
          height: 270px;
          padding: 0 15px;
          margin-bottom: 125px;

          .prod-img-container {
            border: 1px solid black;
            height: 100%;
            width: 100%;

            img {
              // border: 1px solid black;
              height: 100%;
              width: 100%;
              cursor: pointer;
              size: inherit;
            }
          }

          .product-detail {
            height: fit-content;
            .product-name {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 600;
              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-name-perforated {
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 700;
              height: 2.9em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .price-add-cart {
              font-size: 10px;

              .product-calculate-type {
                margin-left: 5px;
              }

              .add-cart-button {
                width: fit-content;
                border: none;
                outline: none;
                background-color: #354b9c;
                color: white;

                &:hover {
                  filter: saturate(0.6);
                  background-color: red;
                }
              }
            }

            .product-price {
              font-size: 12px;
              text-transform: uppercase;
            }

            .request-button {
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .product-page {
    width: 100%;
    height: fit-content;
    padding: 0 20px;
    border: 1px solid black;

    .product-content {
      height: fit-content;

      .product-header {
        width: 100%;
        height: fit-content;
        text-align: center;
        font-size: 30px;
        margin: 10px 0;
        text-transform: uppercase;
        font-weight: bold;
      }

      .product-type-above,
      .product-type-below {
        width: 100%;
        height: 40px;
        margin: 10px 0;
        display: flex;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        gap: 10px;

        .type-change {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          font-size: 18px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
            background: yellow;
            color: blue;
            font-size: 20px;
          }
        }

        .type-change-active {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: inherit;
          border: 1px solid #354b9c;
          border-radius: 10px;
          background: yellow;
          color: blue;
          font-size: 20px;
          border-radius: 20px;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .product-body {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        height: fit-content;

        .slide-product {
          flex: 0 0 25%;
          width: 265px;
          height: 290px;
          padding: 0 15px;
          margin-bottom: 150px;

          .prod-img-container {
            border: 1px solid black;
            height: 100%;
            width: 100%;

            img {
              // border: 1px solid black;
              height: 100%;
              width: 100%;
              cursor: pointer;
              size: inherit;
              
              &:hover {
                cursor: pointer;
                transform: scale(1.1);
                transition: transform 0.3s ease;
              }
            }
          }

          .product-detail {
            height: fit-content;
            .product-name {
              text-transform: uppercase;
              font-size: 15px;
              font-weight: 600;
              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-name-perforated {
              text-transform: uppercase;
              font-size: 15px;
              font-weight: 600;
              height: 2.9em; /* Set the desired height for 2 rows of text */
              overflow: hidden; /* Hide any overflow */
              text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
              display: -webkit-box;
              -webkit-line-clamp: 2; /* Limit the text to 2 lines */
              -webkit-box-orient: vertical;

              &:hover {
                color: #354b9c;
                cursor: pointer;
              }
            }

            .product-price {
              font-size: 14px;
              display: flex;
              text-transform: uppercase;

              .product-calculate-type {
                margin-left: 5px;
              }
            }

            .add-cart-button {
              width: fit-content;
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }
            }

            .request-button {
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }
            }
          }
        }
      }
    }
  }
}
