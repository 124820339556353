#TableManageProduct {
    font-family    : Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width          : 100%;
}

#TableManageProduct td,
#TableManageProduct th {
    border : 1px solid #ddd;
    padding: 8px;
}

#TableManageProduct tr:nth-child(even) {
    background-color: #f2f2f2;
}

#TableManageProduct tr:hover {
    background-color: #ddd;
}

#TableManageProduct th {
    padding-top     : 12px;
    padding-bottom  : 12px;
    background-color: #04aa6d;
    text-align      : center;
    color           : white;

    .typeSelector {
        border : none;
        outline: none;
        width  : 120px;
        color  : black;

        .valueContainer {
            background-color: #04aa6d;
        }
    }
}