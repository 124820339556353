@media (min-width: 1024px) {
  .policy-detail-container {
    height: fit-content;
    margin: 30px 0;
    font-family: serif;

    .policy-choice {
      width: 100%;
      height: 5%;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .policy {
        font-size: 14px;
        font-family: serif;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 20px;
        color: white;
        background-color: #354b9c;

        &:hover {
          cursor: pointer;
          color: #354b9c;
          background-color: yellow;
        }
      }

      .policy-active {
        font-size: 14px;
        font-family: serif;
        font-weight: bold;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 20px;
        color: #354b9c;
        background-color: yellow;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .policy-detail {
      margin-top: 10px;
      width: 100%;
      height: 95%;
      padding: 0 30px;

      .policy-title {
        position: relative;
        font-size: 25px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        border-bottom: 5px solid black;
        margin-bottom: 10px;
      }

      .policy-describe {
        height: calc(100% - 40px);
        font-size: 18px;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .policy-detail-container {
    height: fit-content;
    margin: 10px 0;
    font-family: serif;

    .policy-choice {
      width: 100%;
      height: 5%;
      text-align: center;
      display: flex;
      justify-content: space-between;

      .policy {
        font-size: 6px;
        font-family: serif;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        color: white;
        background-color: #354b9c;

        &:hover {
          cursor: pointer;
          color: #354b9c;
          background-color: yellow;
        }
      }

      .policy-active {
        font-size: 6px;
        font-family: serif;
        font-weight: bold;
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        color: #354b9c;
        background-color: yellow;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .policy-detail {
      margin-top: 5px;
      width: 100%;
      height: 100%;

      h2 {
        font-size: 12px;
        font-weight: bold;
      }

      .policy-title {
        position: relative;
        font-size: 10px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        display: inline-block;
        border-bottom: 5px solid black;
        margin-bottom: 10px;
      }

      .policy-describe {
        height: calc(100% - 40px);
        font-size: 8px;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
