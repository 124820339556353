@media (min-width: 320px) and (max-width: 576px) {
  .popup-container {
    width: 440px;
    height: 100%;

    .popup {
      height: 100%;
      width: 100%;
    }
  }

  .cart-title {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
    font-family: serif;
  }

  .cart-empty-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: serif;
    margin: 10px 0;
    font-size: 10px;

    .empty-cart {
      width: 100%;
      height: 40px;
      font-size: 10px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: red;
    }

    .return-pages {
      width: 100%;
      height: 40px;
      font-size: 10px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      div {
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        margin: 0 10px;
        color: #354b9c;
        background-color: white;
        border: 1px solid black;

        &:hover {
          font-weight: bold;
          background-color: yellow;
          color: #354b9c;
          opacity: 0.9;
        }
      }
    }
  }

  .cart-detail-container {
    width: 100%;
    height: auto;
    display: flex;

    .form-control {
      font-size: 8px;
    }

    table {
      font-size: 8px;
    }

    h5 {
      font-size: 9px;
    }

    th.col-1 {
      width: fit-content;
    }

    .export-info,
    .cust-info {
      span {
        color: red;
      }
    }
    
    .name{
        font-size:8px;
    }
    
    .delete-btn {
      width: fit-content;
      font-size: 6px;
      font-weight: bold;

      border-radius: 10px;
      background: white;
      color: #354b9c;

      &:hover {
        background: yellow;
      }
    }

    .amount {
      width: 100%;

      .decrease,
      .increase {
        display: none;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        text-align: center;
        width: 30px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 3px;
      }
    }

    .form {
      font-size: 8px;
    }

    .item-total-price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 8px;

      .price-number {
        margin: 0 5px;
        color: red;
      }
    }

    .cart-option-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding-left: 10px;
      margin-bottom: 20px;

      .cart-payment {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: serif;
        font-weight: bold;

        .payment-choose {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .payment-text {
            width: 50%;
            font-size: 12px;
          }

          .payment-method {
            font-size: 12px;
            height: fit-content;
          }
        }

        .cashPay,
        .bankPay {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid black;
          border-radius: 10px;
          margin: 10px 0;
          padding: 10px;
          font-size: 10px;
          font-family: serif;
          text-align: center;
        }
      }

      .bill-export {
        width: 100%;
        height: auto;
        gap: 5px;
        font-family: serif;
        font-weight: bold;
        border-top: 1px solid black;

        .bill-option-select {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;

          .bill-text {
            width: 50%;
            font-size: 8px;
          }

          .bill-option {
            font-size: 8px;
            height: fit-content;
          }
        }

        .info-form {
          display: flex;
          flex-direction: column;
          font-family: serif;
          font-size: 8px;
          font-weight: bold;
          border: 1px solid black;
          margin-top: 10px;
          padding: 10px 0;

          span {
            color: red;
          }

          .form-title {
            text-transform: uppercase;
            text-align: center;
          }

          .first-form,
          .second-form {
            display: flex;
          }

          .first-form-below,
          .second-form-below {
            display: flex;
          }
        }
      }
    }
  }

  .commit-btn {
    width: 100%;
    height: fit-content;
    background: #354b9c;
    color: yellow;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 20px;

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;
    }
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999;
    font-size: 12px;
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .popup-container {
    width: 440px;
    height: 100%;

    .popup {
      height: 100%;
      width: 100%;
    }
  }

  .cart-title {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
    font-family: serif;
  }

  .cart-empty-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: serif;
    margin: 10px 0;
    font-size: 10px;

    .empty-cart {
      width: 100%;
      height: 40px;
      font-size: 10px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: red;
    }

    .return-pages {
      width: 100%;
      height: 40px;
      font-size: 10px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      div {
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        margin: 0 10px;
        color: #354b9c;
        background-color: white;
        border: 1px solid black;

        &:hover {
          font-weight: bold;
          background-color: yellow;
          color: #354b9c;
          opacity: 0.9;
        }
      }
    }
  }

  .cart-detail-container {
    width: 100%;
    height: auto;
    display: flex;

    .form-control {
      font-size: 8px;
    }

    table {
      font-size: 8px;
    }

    h5 {
      font-size: 8px;
    }

    th.col-1 {
      width: fit-content;
    }

    .export-info,
    .cust-info {
      span {
        color: red;
      }

      .col-4 {
        width: 100%;
        display: flex;
      }
    }

    .name {
      font-size: 8px;
    }

    .delete-btn {
      width: fit-content;
      font-size: 6px;
      font-weight: bold;

      border-radius: 5px;
      background: white;
      color: #354b9c;

      &:hover {
        background: yellow;
      }
    }

    .amount {
      width: 100%;

      .decrease,
      .increase {
        display: none;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        text-align: center;
        width: 30px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 3px;
      }
    }

    .form {
      font-size: 8px;
    }

    .item-total-price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 8px;

      .price-number {
        margin: 0 5px;
        color: red;
      }
    }

    .cart-option-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding-left: 10px;
      margin-bottom: 20px;

      .cart-payment {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: serif;
        font-weight: bold;

        .payment-choose {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .payment-text {
            width: 50%;
            font-size: 12px;
          }

          .payment-method {
            font-size: 12px;
            height: fit-content;
          }
        }

        .cashPay,
        .bankPay {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid black;
          border-radius: 10px;
          margin: 10px 0;
          padding: 10px;
          font-size: 10px;
          font-family: serif;
          text-align: center;
        }
      }

      .bill-export {
        width: 100%;
        height: auto;
        gap: 5px;
        font-family: serif;
        font-weight: bold;
        border-top: 1px solid black;

        .bill-option-select {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;

          .bill-text {
            width: 50%;
            font-size: 8px;
          }

          .bill-option {
            font-size: 8px;
            height: fit-content;
          }
        }

        .info-form {
          display: flex;
          flex-direction: column;
          font-family: serif;
          font-size: 8px;
          font-weight: bold;
          border: 1px solid black;
          margin-top: 10px;
          padding: 10px 0;

          span {
            color: red;
          }

          .form-title {
            text-transform: uppercase;
            text-align: center;
          }

          .first-form,
          .second-form {
            display: flex;
          }

          .first-form-below,
          .second-form-below {
            display: flex;
          }
        }
      }
    }
  }

  .commit-btn {
    width: 100%;
    height: fit-content;
    background: #354b9c;
    color: yellow;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 20px;

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;
    }
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999;
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .popup-container {
    width: 440px;
    height: 100%;

    .popup {
      height: 100%;
      width: 100%;
    }
  }

  .cart-title {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
    font-family: serif;
  }

  .cart-empty-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: serif;
    margin: 10px 0;

    .empty-cart {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: red;
    }

    .return-pages {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      div {
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        margin: 0 10px;
        color: #354b9c;
        background-color: white;
        border: 1px solid black;

        &:hover {
          font-weight: bold;
          background-color: yellow;
          color: #354b9c;
          opacity: 0.9;
        }
      }
    }
  }

  .cart-detail-container {
    width: 100%;
    height: auto;
    display: flex;

    .export-info,
    .cust-info {
      span {
        color: red;
      }
    }

    .name {
      font-size: 20px;
      font-weight: bold;
    }
    
    .delete-btn {
      width: fit-content;
      font-size: 18px;
      font-weight: bold;

      border-radius: 10px;
      background: white;
      color: #354b9c;

      &:hover {
        background: yellow;
      }
    }

    .amount {
      display: flex;
      align-items: center;

      .decrease,
      .increase {
        border: 1px solid black;
        display: inline-block;
        text-align: center;
        width: fit-content;
        height: fit-content;

        button {
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
          background-color: white;

          &:hover {
            color: #354b9c;
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        text-align: center;
        width: 50px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 5px;
      }
    }

    .form {
      font-size: 15px;
    }

    .item-total-price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;

      .price-number {
        margin: 0 5px;
        color: red;
      }
    }

    

    .cart-option-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding-left: 10px;
      margin-bottom: 20px;

      .cart-payment {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: serif;
        font-weight: bold;

        .payment-choose {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .payment-text {
            width: 50%;
            font-size: 20px;
          }

          .payment-method {
            font-size: 20px;
            height: fit-content;
          }
        }

        .cashPay,
        .bankPay {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid black;
          border-radius: 10px;
          margin: 10px 0;
          padding: 10px;
          font-size: 18px;
          font-family: serif;
          text-align: center;
        }
      }

      .bill-export {
        width: 100%;
        height: auto;
        gap: 10px;
        font-family: serif;
        font-weight: bold;
        border-top: 1px solid black;

        .bill-option-select {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;

          .bill-text {
            width: 50%;
            font-size: 20px;
          }

          .bill-option {
            font-size: 20px;
            height: fit-content;
          }
        }

        .info-form {
          display: flex;
          flex-direction: column;
          font-family: serif;
          font-size: 15px;
          font-weight: bold;
          border: 1px solid black;
          margin-top: 10px;
          padding: 10px 0;

          span {
            color: red;
          }

          .form-title {
            text-transform: uppercase;
            text-align: center;
          }

          .first-form,
          .second-form {
            display: flex;
          }

          .first-form-below,
          .second-form-below {
            display: flex;
          }
        }
      }
    }
  }

  .commit-btn {
    width: 100%;
    height: fit-content;
    background: #354b9c;
    color: yellow;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 20px;

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;
    }
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .popup-container {
    width: 440px;
    height: 100%;

    .popup {
      height: 100%;
      width: 100%;
    }
  }

  .cart-title {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
    font-family: serif;
  }

  .cart-empty-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: serif;
    margin: 10px 0;

    .empty-cart {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: red;
    }

    .return-pages {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      div {
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        margin: 0 10px;
        color: #354b9c;
        background-color: white;
        border: 1px solid black;

        &:hover {
          font-weight: bold;
          background-color: yellow;
          color: #354b9c;
          opacity: 0.9;
        }
      }
    }
  }

  .cart-detail-container {
    width: 100%;
    height: auto;
    display: flex;

    .export-info,
    .cust-info {
      span {
        color: red;
      }
    }

    .name {
      font-size: 20px;
      font-weight: bold;
    }
    
    .delete-btn {
      width: fit-content;
      font-size: 18px;
      font-weight: bold;

      border-radius: 10px;
      background: white;
      color: #354b9c;

      &:hover {
        background: yellow;
      }
    }

    .amount {
      display: flex;
      align-items: center;

      .decrease,
      .increase {
        border: 1px solid black;
        display: inline-block;
        text-align: center;
        width: fit-content;
        height: fit-content;

        button {
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
          background-color: white;

          &:hover {
            color: #354b9c;
          }
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        text-align: center;
        width: 50px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 5px;
      }
    }

    .form {
      font-size: 15px;
    }

    .item-total-price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;

      .price-number {
        margin: 0 5px;
        color: red;
      }
    }

    .cart-option-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding-left: 10px;
      margin-bottom: 20px;

      .cart-payment {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: serif;
        font-weight: bold;

        .payment-choose {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .payment-text {
            width: 50%;
            font-size: 20px;
          }

          .payment-method {
            font-size: 20px;
            height: fit-content;
          }
        }

        .cashPay,
        .bankPay {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid black;
          border-radius: 10px;
          margin: 10px 0;
          padding: 10px;
          font-size: 18px;
          font-family: serif;
          text-align: center;
        }
      }

      .bill-export {
        width: 100%;
        height: auto;
        gap: 10px;
        font-family: serif;
        font-weight: bold;
        border-top: 1px solid black;

        .bill-option-select {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;

          .bill-text {
            width: 50%;
            font-size: 20px;
          }

          .bill-option {
            font-size: 20px;
            height: fit-content;
          }
        }

        .info-form {
          display: flex;
          flex-direction: column;
          font-family: serif;
          font-size: 15px;
          font-weight: bold;
          border: 1px solid black;
          margin-top: 10px;
          padding: 10px 0;

          span {
            color: red;
          }

          .form-title {
            text-transform: uppercase;
            text-align: center;
          }

          .first-form,
          .second-form {
            display: flex;
          }

          .first-form-below,
          .second-form-below {
            display: flex;
          }
        }
      }
    }
  }

  .commit-btn {
    width: 100%;
    height: fit-content;
    background: #354b9c;
    color: yellow;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 20px;

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;
    }
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999;
  }
}

@media (min-width: 1200px) {
  .popup-container {
    width: 440px;
    height: 100%;

    .popup {
      height: 100%;
      width: 100%;
    }
  }

  .cart-title {
    width: 100%;
    height: fit-content;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
    font-family: serif;
  }

  .cart-empty-container {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family: serif;
    margin: 10px 0;

    .empty-cart {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      color: red;
    }

    .return-pages {
      width: 100%;
      height: 40px;
      font-size: 20px;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
      display: flex;
      justify-content: center;

      div {
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        margin: 0 10px;
        color: #354b9c;
        background-color: white;
        border: 1px solid black;

        &:hover {
          font-weight: bold;
          background-color: yellow;
          color: #354b9c;
          opacity: 0.9;
        }
      }
    }
  }

  .cart-detail-container {
    width: 100%;
    height: auto;
    display: flex;

    .export-info,
    .cust-info {
      span {
        color: red;
      }
    }
    .name {
      font-size: 14px;
      font-weight: bold;
    }

    .delete-btn {
      width: fit-content;
      font-size: 12px;
      font-weight: bold;

      border-radius: 10px;
      background: white;
      color: #354b9c;

      &:hover {
        background: yellow;
      }
    }

    .amount {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;

      .decrease,
      .increase {
        border: 1px solid black;
        outline: none;
        background-color: white;
        font-size: 10px;

        &:hover {
          color: #354b9c;
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        text-align: center;
        width: 50px;
        height: fit-content;
        background: #fff;
        border: 1px solid black;
        margin: 0 2px;
      }
    }

    .item-total-price {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;

      .price-number {
        margin: 0 5px;
        color: red;
      }
    }

    .cart-option-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      height: auto;
      padding-left: 10px;
      margin-bottom: 20px;

      .cart-payment {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: serif;
        font-weight: bold;

        .payment-choose {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .payment-text {
            width: 50%;
            font-size: 20px;
          }

          .payment-method {
            font-size: 20px;
            height: fit-content;
          }
        }

        .cashPay,
        .bankPay {
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid black;
          border-radius: 10px;
          margin: 10px 0;
          padding: 10px;
          font-size: 18px;
          font-family: serif;
          text-align: center;
        }
      }

      .bill-export {
        width: 100%;
        height: auto;
        gap: 5px;
        font-family: serif;
        font-weight: bold;
        border-top: 1px solid black;

        .bill-option-select {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;

          .bill-text {
            width: 50%;
            font-size: 20px;
          }

          .bill-option {
            font-size: 20px;
            height: fit-content;
          }
        }

        .info-form {
          display: flex;
          flex-direction: column;
          font-family: serif;
          font-size: 15px;
          font-weight: bold;
          border: 1px solid black;
          margin-top: 10px;
          padding: 10px 0;

          span {
            color: red;
          }

          .form-title {
            text-transform: uppercase;
            text-align: center;
          }

          .first-form,
          .second-form {
            display: flex;
          }

          .first-form-below,
          .second-form-below {
            display: flex;
          }
        }
      }
    }
  }

  .commit-btn {
    width: 100%;
    height: fit-content;
    background: #354b9c;
    color: yellow;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    outline: none;
    margin-bottom: 20px;

    &:hover {
      transition: all 0.5s;
      opacity: 0.8;
    }
  }

  .alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    z-index: 999;
  }
}
