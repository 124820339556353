@media (min-width: 320px) and (max-width: 576px) {
  .contact-container {
    .detail-contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 50px;
        height: 45px;
      }
      h4 {
        color: #354b9c;
        font-size: 10px;
      }

      h6 {
        color: black;
        font-size: 8px;
      }
    }
    .contact-form {
      h3 {
        font-size: 12px;
      }
      label {
        font-size: 8px;
      }
      input {
        font-size: 8px;
      }
      span {
        color: red;
      }

      button {
        font-size: 8px;
        background: #354b9c;
        outline: none;
        border: none;
        color: white;

        &:hover {
          color: yellow;
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .contact-container {
    .detail-contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 50px;
        height: 45px;
      }
      h4 {
        color: #354b9c;
        font-size: 10px;
      }

      h6 {
        color: black;
        font-size: 8px;
      }
    }
    .contact-form {
      h3 {
        font-size: 12px;
      }
      label {
        font-size: 8px;
      }
      input {
        font-size: 8px;
      }
      span {
        color: red;
      }

      button {
        font-size: 8px;
        background: #354b9c;
        outline: none;
        border: none;
        color: white;

        &:hover {
          color: yellow;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-container {
    .detail-contact {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        width: 50px;
        height: 45px;
      }
      h4 {
        color: #354b9c;
        font-size: 14px;
      }

      h6 {
        color: black;
        font-size: 12px;
      }
    }
    .contact-form {
      h3 {
        font-size: 14px;
      }
      label {
        font-size: 12px;
      }
      input {
        font-size: 12px;
      }
      span {
        color: red;
      }

      button {
        font-size: 12px;
        background: #354b9c;
        outline: none;
        border: none;
        color: white;

        &:hover {
          color: yellow;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .contact-container {
    h4 {
      color: #354b9c;
    }

    h6 {
      color: black;
    }
    span {
      color: red;
    }
    button {
      background: #354b9c;
      outline: none;
      border: none;
      color: white;

      &:hover {
        color: yellow;
      }
    }
    
    .preview-img{
        width: 100%;
    }
  }
}
