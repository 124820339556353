@media (min-width: 320px) and (max-width: 576px) {
  //Home header
  .home-header-container {
    background: #dadddd;

    .col-7 {
      width: fit-content;
      height: fit-content;

      .co-logo {
        width: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .co-info {
        height: 100%;
      }
    }

    h5 {
      color: #354b9c;
      font-size: 8px;
      margin: 0;
    }

    h6 {
      font-size: 6px;
      margin: 0;
    }

    .to-options-button {
      font-size: 8px;

      i.fas.fa-search {
        &:hover {
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }

      .to-cart-button {
        &:hover {
          display: block;
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  //Tab
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #354b9c;

    .tab {
      color: white;

      h5 {
        font-size: 6px;
      }

      &:active {
        color: yellow;
        cursor: pointer;
      }
    }
  }
  .tab-nav-container {
    box-shadow: 20px 20px 50px 15px rgba(0, 0, 0, 0.412);
    border-radius: 20px;

    .tab-child {
      background-color: white;
      outline: none;
      border: none;
      border-radius: 20px;

      .tab-nav {
        font-size: 9px;
        &:active {
          // background-color: #354b9c;
          color: #354b9c;
          cursor: pointer;
        }
      }
    }
  }

  //Banner
  .banner {
    min-width: 360px;
    max-width: 1920px;
    height: 13vh;

    img {
      width: 100%;
      height: 100%;
    }

    .slick-slider {
      outline: none;
    }
  }

  //Product sections
  .product-section-container {
    background-color: #fafafa;
    .section-header-container {
      h2 {
        font-size: 12px;
      }
      .see-more {
        border: none;
        outline: none;
        background-color: #dadddd;
        color: black;
        font-size: 8px;
        margin-bottom: 7px;

        &:hover {
          background-color: #354b9c;
          color: white;
        }
      }
    }

    .product-slider-container {
      .product-container {
        h5.font-weight-bold {
          font-size: 6px;
          height: 3.5em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;
          margin: 0;

          &:active {
            color: #354b9c;
          }
        }

        h5.perforated-name {
          font-size: 6px;
          height: 5em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;
          margin: 0;

          &:active {
            color: #354b9c;
          }
        }

        .price-add-cart {
          .product-price-container {
            display: flex;
            flex-direction: column;
            font-size: 5px;
            font-weight: bold;
          }

          .buttons {
            display: flex;
            flex-direction: column;
            gap: 1px;
            .add-cart-button {
              font-size: 6px;
              width: 100%;
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }

              span.add-cart {
                display: none;
              }
            }
          }

          .product-price {
            font-size: 9px;
          }
          .request-button {
            font-size: 6px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
            background-color: #354b9c;
            color: white;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }

          .request-form {
            font-size: 6px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
          }
        }
      }
    }
  }

  //reset slick slider css
  .slick-prev::before,
  .slick-next::before {
    color: #000 !important;
    font-family: FontAwesome;
    font-size: 8px !important;
  }

  .slick-prev {
    top: 30px !important;
    left: -5px !important;
    z-index: 1000 !important;
    height: 18px !important;
    width: 9px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-next {
    top: 30px !important;
    right: -5px !important;
    z-index: 1000 !important;
    height: 18px !important;
    width: 9px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-disabled {
    display: none !important;
  }

  //footer
  .home-footer-container {
    background: #dadddd;

    h4 {
      font-size: 10px;
      color: #354b9c;
    }

    span {
      font-size: 8px;
    }

    .py-1 {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .intro-container {
        width: 100%;
      }

      .branch-container {
        width: 100%;

        .col-3 {
          font-size: 9px;
        }
      }

      .policy-container {
        display: flex;
        width: 100%;

        .policy-nav {
          font-size: 8px;

          .policy-active {
            color: #354b9c;
          }
        }
        
        a {
          img {
            width: 200px;
            height: 90px;
          }
        }
        
      }
    }

    .copyrights-container {
      background-color: white;
    }
  }

  //contact ways
  .contact-ways {
    .phone {
      border-radius: 30px;
      padding: 5px;
      position: fixed;
      background-color: #0c9;
      bottom: 80px;
      right: 13px;
      z-index: 1003;

      a {
        color: black;
      }

      i {
        font-size: 25px;
        padding: 10px;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }

      .phone-number {
        position: fixed;
        bottom: 85px;
        right: 70px;
        z-index: 1003;

        font-size: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .zalo {
      position: fixed;
      bottom: 145px;
      right: 15px;
      z-index: 1003;
      background-color: #0068ff;
      border-radius: 30px;

      &:hover {
        cursor: pointer;
      }

      .zaloImg {
        width: 55px;
        height: 55px;
      }

      .zalo-number {
        position: fixed;
        bottom: 150px;
        right: 70px;
        z-index: 1003;
        font-size: 15px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }
  }
  
  .copyrights-container {
    display: flex;
    flex-direction: column;
    .business-check{
        font-size:8px;
    }
    .gov-check {
        justify-content: space-between;
        
      img {
        height: 50px;
        width: 100px;
      }
    }

    .col-8 {
      margin-top: 10px;
      h6 {
        font-size: 6px;
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  //Home header
  .home-header-container {
    background: #dadddd;

    .col-7 {
      width: fit-content;
      height: fit-content;

      .co-logo {
        width: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .co-info {
        height: 100%;
      }
    }

    h5 {
      color: #354b9c;
      font-size: 8px;
      margin: 0;
    }

    h6 {
      font-size: 6px;
      margin: 0;
    }

    .to-options-button {
      font-size: 8px;

      i.fas.fa-search {
        &:hover {
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }

      .to-cart-button {
        &:hover {
          display: block;
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  //Tab
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #354b9c;

    .tab {
      color: white;

      h5 {
        font-size: 6px;
      }

      &:active {
        color: yellow;
        cursor: pointer;
      }
    }
  }
  .tab-nav-container {
    box-shadow: 20px 20px 50px 15px rgba(0, 0, 0, 0.412);
    border-radius: 20px;

    .tab-child {
      background-color: white;
      outline: none;
      border: none;
      border-radius: 20px;

      .tab-nav {
        font-size: 9px;
        &:active {
          // background-color: #354b9c;
          color: #354b9c;
          cursor: pointer;
        }
      }
    }
  }

  //Banner
  .banner {
    min-width: 360px;
    max-width: 1920px;
    height: 13vh;

    img {
      width: 100%;
      height: 100%;
    }

    .slick-slider {
      outline: none;
    }
  }

  //Product sections
  .product-section-container {
    background-color: #fafafa;
    .section-header-container {
      h2 {
        font-size: 12px;
      }
      .see-more {
        border: none;
        outline: none;
        background-color: #dadddd;
        color: black;
        font-size: 8px;
        margin-bottom: 7px;

        &:hover {
          background-color: #354b9c;
          color: white;
        }
      }
    }

    .product-slider-container {
      .product-container {
        h5.font-weight-bold {
          font-size: 8px;
          height: 3em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:active {
            color: #354b9c;
          }
        }

        h5.perforated-name {
          font-size: 8px;
          height: 4em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:active {
            color: #354b9c;
          }
        }

        .price-add-cart {
          .product-price-container {
            display: flex;
            flex-direction: column;
            font-size: 6px;
          }

          .buttons {
            display: flex;
            flex-direction: column;
            gap: 1px;
            .add-cart-button {
              font-size: 6px;
              width: 100%;
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }

              span.add-cart {
                display: none;
              }
            }
          }

          .product-price {
            font-size: 9px;
          }
          .request-button {
            font-size: 6px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
            background-color: #354b9c;
            color: white;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }

          .request-form {
            font-size: 6px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
          }
        }
      }
    }
  }

  //reset slick slider css
  .slick-prev::before,
  .slick-next::before {
    color: #000 !important;
    font-family: FontAwesome;
    font-size: 10px !important;
  }

  .slick-prev {
    top: 50px !important;
    left: 0px !important;
    z-index: 1000 !important;
    height: 20px !important;
    width: 10px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-next {
    top: 50px !important;
    right: 0px !important;
    z-index: 1000 !important;
    height: 20px !important;
    width: 10px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-disabled {
    display: none !important;
  }

  //footer
  .home-footer-container {
    background: #dadddd;

    h4 {
      font-size: 10px;
      color: #354b9c;
    }

    span {
      font-size: 8px;
    }

    .py-1 {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .intro-container {
        width: 100%;
      }

      .branch-container {
        width: 100%;

        .col-3 {
          font-size: 9px;
        }
      }

      .policy-container {
        display: flex;
        width: 100%;

        .policy-nav {
          font-size: 8px;

          .policy-active {
            color: #354b9c;
          }
        }

        a {
          img {
            width: 230px;
            height: 100px;
          }
        }
      }
    }

    .copyrights-container {
      background-color: white;
    }
  }

  //contact ways
  .contact-ways {
    .phone {
      border-radius: 30px;
      padding: 5px;
      position: fixed;
      background-color: #0c9;
      bottom: 80px;
      right: 13px;
      z-index: 1003;

      a {
        color: black;
      }

      i {
        font-size: 25px;
        padding: 10px;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }

      .phone-number {
        position: fixed;
        bottom: 85px;
        right: 70px;
        z-index: 1003;

        font-size: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .zalo {
      position: fixed;
      bottom: 145px;
      right: 15px;
      z-index: 1003;
      background-color: #0068ff;
      border-radius: 30px;

      &:hover {
        cursor: pointer;
      }

      .zaloImg {
        width: 55px;
        height: 55px;
      }

      .zalo-number {
        position: fixed;
        bottom: 150px;
        right: 70px;
        z-index: 1003;
        font-size: 15px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }
  }
  
  .copyrights-container {
    display: flex;
    flex-direction: column;
    .business-check{
        font-size:8px;
    }
    .gov-check {
        justify-content: space-between;
      img {
        height: 50px;
      }
    }

    .col-8 {
      h6 {
        font-size: 6px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  //Home header
  .home-header-container {
    background: #dadddd;

    .col-8 {
      width: fit-content;
      height: fit-content;

      .co-logo {
        width: 70px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .co-info {
        height: 100%;
      }
    }

    h5 {
      color: #354b9c;
      font-size: 15px;
    }

    h6 {
      font-size: 12px;
    }

    .to-options-button {
      font-size: 12px;

      i.fas.fa-search {
        &:hover {
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }

      .to-cart-button {
        &:hover {
          display: block;
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  //Tab
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #354b9c;

    .tab {
      color: white;

      h5 {
        font-size: 12px;
        font-weight: bold;
      }

      &:active {
        color: yellow;
        cursor: pointer;
      }
    }
  }
  .tab-nav-container {
    box-shadow: 20px 20px 50px 15px rgba(0, 0, 0, 0.412);
    border-radius: 20px;

    .tab-child {
      background-color: white;
      outline: none;
      border: none;
      border-radius: 20px;

      .tab-nav {
        font-size: 12px;
        &:active {
          // background-color: #354b9c;
          color: #354b9c;
          cursor: pointer;
        }
      }
    }
  }

  //Banner
  .banner {
    min-width: 360px;
    max-width: 1920px;
    height: 13vh;

    img {
      width: 100%;
      height: 100%;
    }

    .slick-slider {
      outline: none;
    }
  }

  //Product sections
  .product-section-container {
    background-color: #fafafa;
    .section-header-container {
      h2 {
        font-size: 12px;
      }
      .see-more {
        border: none;
        outline: none;
        background-color: #dadddd;
        color: black;
        font-size: 8px;
        margin-bottom: 7px;

        &:hover {
          background-color: #354b9c;
          color: white;
        }
      }
    }

    .product-slider-container {
      .product-container {
        h5.font-weight-bold {
          font-size: 10px;
          height: 2.5em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:active {
            color: #354b9c;
          }
        }

        h5.perforated-name {
          font-size: 10px;
          height: 3.6em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 5; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:active {
            color: #354b9c;
          }
        }

        .price-add-cart {
          .product-price-container {
            display: flex;
            flex-direction: column;
            font-size: 10px;
          }

          .buttons {
            display: flex;
            flex-direction: column;
            gap: 1px;
            .add-cart-button {
              font-size: 10px;
              width: 100%;
              border: none;
              outline: none;
              background-color: #354b9c;
              color: white;

              &:hover {
                filter: saturate(0.6);
                background-color: red;
              }
            }
          }

          .product-price {
            font-size: 10px;
          }
          .request-button {
            font-size: 10px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
            background-color: #354b9c;
            color: white;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }

          .request-form {
            font-size: 10px;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
          }
        }
      }
    }
  }

  //reset slick slider css
  .slick-prev::before,
  .slick-next::before {
    color: #000 !important;
    font-family: FontAwesome;
    font-size: 12px !important;
  }

  .slick-prev {
    top: 75px !important;
    left: -5px !important;
    z-index: 1000 !important;
    height: 30px !important;
    width: 20px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-next {
    top: 75px !important;
    right: -5px !important;
    z-index: 1000 !important;
    height: 30px !important;
    width: 20px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-disabled {
    display: none !important;
  }

  //footer
  .home-footer-container {
    background: #dadddd;
    height: fit-content;
    h4 {
      font-size: 14px;
      color: #354b9c;
    }

    span {
      font-size: 12px;
    }

    .py-1 {
      display: flex;
      flex-direction: column;

      .intro-container {
        width: 100%;
        height: fit-content;
      }

      .branch-container {
        width: 100%;
        height: fit-content;

        .col-3 {
          font-size: 12px;
        }
      }

      .policy-container {
        display: flex;
        width: 100%;
        height: fit-content;

        .policy-nav {
          font-size: 12px;

          .policy-active {
            color: #354b9c;
          }
        }

        a {
          img {
            width: 250px;
            height: 110px;
          }
        }
      }
    }

    .copyrights-container {
      background-color: white;
    }
  }

  //contact ways
  .contact-ways {
    .phone {
      border-radius: 40px;
      padding: 5px;
      position: fixed;
      background-color: #0c9;
      bottom: 90px;
      right: 25px;
      z-index: 1003;

      a {
        color: black;
      }

      i {
        font-size: 30px;
        padding: 10px;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }

      .phone-number {
        position: fixed;
        bottom: 90px;
        right: 90px;
        z-index: 1003;

        font-size: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .zalo {
      position: fixed;
      bottom: 160px;
      right: 25px;
      z-index: 1003;
      background-color: #0068ff;
      border-radius: 30px;

      &:hover {
        cursor: pointer;
      }

      .zaloImg {
        width: 60px;
        height: 60px;
      }

      .zalo-number {
        position: fixed;
        bottom: 160px;
        right: 90px;
        z-index: 1003;
        font-size: 20px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }
  }
  
  .copyrights-container {
    display: flex;
    flex-direction: column;
    .business-check{
        font-size:12px;
    }
    .gov-check {
        justify-content: space-between;
      img {
        height: 50px;
        width: 100px;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-header-container {
    background: #dadddd;

    h5 {
      color: #354b9c;
    }

    .to-options-button {
      i.fas.fa-search {
        &:hover {
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }

      .to-cart-button {
        &:hover {
          display: block;
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  //Tab
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #354b9c;
    font-family: serif;

    .tab {
      color: white;

      &:hover {
        color: yellow;
        cursor: pointer;
      }
    }
  }
  .tab-nav-container {
    box-shadow: 20px 20px 50px 15px rgba(0, 0, 0, 0.412);
    border-radius: 20px;

    .tab-child {
      background-color: white;
      outline: none;
      border: none;
      border-radius: 20px;

      .tab-nav {
        &:hover {
          // background-color: #354b9c;
          background-color: yellow;
          color: #354b9c;
          cursor: pointer;
        }
      }
    }
  }

  //Discount
  .discount-container {
    background: #dadddd;
  }

  //Banner
  .banner {
    min-width: 769px;
    max-width: 1920px;
    height: 20vh;

    img {
      width: 100%;
      height: 100%;
    }

    .slick-slider {
      outline: none;
    }
  }

  //Product sections
  .product-section-container {
    background-color: #fafafa;
    .section-header-container {
      .see-more {
        border: none;
        outline: none;
        background-color: #dadddd;
        color: black;

        &:hover {
          background-color: #354b9c;
          color: white;
        }
      }
    }

    .product-slider-container {
      .product-container {
        img {
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
            transition: transform 0.3s ease;
          }
        }  
        
        h5.perforated-name {
          height: 2.5em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:hover {
            color: #354b9c;
          }
        }

        .price-add-cart {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .product-price-container {
            font-size: 10px;
          }
          .add-cart-button {
            width: fit-content;
            border: none;
            outline: none;
            background-color: #354b9c;
            color: white;
            font-size: 12px;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }

          .request-button {
            border: none;
            outline: none;
            background-color: #354b9c;
            color: white;
            font-size: 12px;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }
        }
      }
    }
  }

  //reset slick slider css

  .slick-prev::before,
  .slick-next::before {
    color: #000 !important;
    font-family: "Font Awesome 5 Free";
  }

  .slick-prev {
    top: 170px !important;
    left: -20px !important;
    z-index: 1000 !important;
    height: 50px !important;
    width: 35px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-next {
    top: 170px !important;
    right: -21px !important;
    z-index: 1000 !important;
    height: 50px !important;
    width: 35px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-disabled {
    display: none !important;
  }

  //footer
  .home-footer-container {
    font-family: serif;
    background: #dadddd;

    div.py-1 {
      display: flex;

      .intro-container {
        width: 30%;
      }

      .branch-container {
        width: 45%;
      }

      .policy-container {
        width: 25%;
        
        .policy-nav {
         &:hover {
          color: #354b9c;
          cursor: pointer;
         }
        }
        
        a{
         img {
          height: 130px;
          width: 250px;
         }
        }
      }
    }
    

    h4 {
      color: #354b9c;
    }

    

    .copyrights-container {
      background-color: white;
    }
  }

  //contact ways
  .contact-ways {
    transition: 400ms;

    .phone {
      border-radius: 35px;
      padding: 5px;
      position: fixed;
      background-color: #0c9;
      bottom: 100px;
      right: 25px;
      z-index: 1003;

      a {
        color: black;
      }

      i {
        font-size: 30px;
        padding: 10px;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }

      .phone-number {
        position: fixed;
        bottom: 100px;
        right: 90px;
        z-index: 1003;

        font-size: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .zalo {
      position: fixed;
      bottom: 175px;
      right: 25px;
      z-index: 1003;
      background-color: #0068ff;
      border-radius: 30px;

      &:hover {
        cursor: pointer;
      }

      .zaloImg {
        width: 61px;
        height: 61px;
      }

      .zalo-number {
        position: fixed;
        bottom: 175px;
        right: 90px;
        z-index: 1003;
        font-size: 20px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }
  }
  
  .copyrights-container{
    .business-check{
        width:75%;
        font-size: 12px;
    }
    .gov-check {
        width:25%;
        display: flex;

        img {
        height: 80px;
        margin-right: 15px;
        }
    }
  }
}

@media (min-width: 1200px) {
  .home-header-container {
    background: #dadddd;

    h5 {
      color: #354b9c;
    }
    
    .co-info{
        margin-left: 1px;
    }

    .to-options-button {
      i.fas.fa-search {
        &:hover {
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }

      .to-cart-button {
        &:hover {
          display: block;
          color: #354b9c;
          transition: ease-in-out 0.5s;
          cursor: pointer;
        }
      }
    }
  }

  //Tab
  .tab-container {
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #354b9c;
    font-family: serif;

    .tab {
      color: white;

      &:hover {
        color: yellow;
        cursor: pointer;
      }
    }
  }
  .tab-nav-container {
    box-shadow: 20px 20px 50px 15px rgba(0, 0, 0, 0.412);
    border-radius: 20px;

    .tab-child {
      background-color: white;
      outline: none;
      border: none;
      border-radius: 20px;

      .tab-nav {
        &:hover {
          // background-color: #354b9c;
          background-color: yellow;
          color: #354b9c;
          cursor: pointer;
        }
      }
    }
  }

  //Discount
  .discount-container {
    background: #dadddd;
  }

  //Banner
  .banner {
    min-width: 769px;
    max-width: 1920px;
    height: 55vh;

    img {
      width: 100%;
      height: 100%;
    }

    .slick-slider {
      outline: none;
    }
  }

  //Product sections
  .product-section-container {
    background-color: #fafafa;
    .section-header-container {
      .see-more {
        border: none;
        outline: none;
        background-color: #dadddd;
        color: black;

        &:hover {
          background-color: #354b9c;
          color: white;
        }
      }
    }

    .product-slider-container {
      .product-container {
        img {
          &:hover {
            cursor: pointer;
            transform: scale(1.1);
            transition: transform 0.3s ease;
          }
        }
        
        h5.perforated-name {
          height: 2.5em; /* Set the desired height for 2 rows of text */
          overflow: hidden; /* Hide any overflow */
          text-overflow: ellipsis; /* Add ellipsis if the text exceeds the height */
          display: -webkit-box;
          -webkit-line-clamp: 2; /* Limit the text to 2 lines */
          -webkit-box-orient: vertical;

          &:hover {
            color: #354b9c;
          }
        }

        .price-add-cart {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .product-price-container {
            font-size: 14px;
          }
          .add-cart-button {
            width: fit-content;
            border: none;
            outline: none;
            background-color: #354b9c;
            color: white;
            font-size: 17px;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }

          .request-button {
            border: none;
            outline: none;
            background-color: #354b9c;
            color: white;
            font-size: 17px;

            &:hover {
              filter: saturate(0.6);
              background-color: red;
            }
          }
        }
      }
    }
  }

  //reset slick slider css

  .slick-prev::before,
  .slick-next::before {
    color: #000 !important;
    font-family: "Font Awesome 5 Free";
  }

  .slick-prev {
    top: 170px !important;
    left: -20px !important;
    z-index: 1000 !important;
    height: 50px !important;
    width: 35px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-next {
    top: 170px !important;
    right: -21px !important;
    z-index: 1000 !important;
    height: 50px !important;
    width: 35px !important;
    background: #fff !important;
    border: 1px solid #555 !important;
  }

  .slick-disabled {
    display: none !important;
  }

  //footer
  .home-footer-container {
    font-family: serif;
    background: #dadddd;

    div.py-1 {
      display: flex;

      .intro-container {
        width: 30%;
      }

      .branch-container {
        width: 40%;
      }

      .policy-container {
        width: 30%;

        .policy-nav {
          &:hover {
            color: #354b9c;
            cursor: pointer;
          }
        }
        
        a {
          img {
            width: 370px;
            height: 130px;
          }
        }
      }
    }
    h4 {
      color: #354b9c;
    }
  }
  
  .copyrights-container {
    margin: 10px 0;
    width: 100%;
    background-color: white;
  }

  //contact ways
  .contact-ways {
    transition: 400ms;

    .phone {
      border-radius: 30px;
      padding: 5px;
      position: fixed;
      background-color: #0c9;
      bottom: 100px;
      right: 25px;
      z-index: 1003;

      a {
        color: black;
      }

      i {
        font-size: 30px;
        padding: 10px;
        color: white;
      }

      &:hover {
        cursor: pointer;
      }

      .phone-number {
        position: fixed;
        bottom: 100px;
        right: 90px;
        z-index: 1003;

        font-size: 20px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .zalo {
      position: fixed;
      bottom: 175px;
      right: 25px;
      z-index: 1003;
      background-color: #0068ff;
      border-radius: 30px;

      &:hover {
        cursor: pointer;
      }

      .zaloImg {
        width: 61px;
        height: 61px;
      }

      .zalo-number {
        position: fixed;
        bottom: 175px;
        right: 90px;
        z-index: 1003;
        font-size: 20px;

        &:hover {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }
  }
  .copyrights-container{
    .business-check{
        width:70%;
    }
    .gov-check {
        width:30%;
        display: flex;

        img {
        height: 80px;
        margin-right: 15px;
        }
    }
  }
}

.menu-item.tab.col{
    transition: background-color ease-in-out;
    &:hover{
        background-color: #04aa6d;
    }
}