#TableManagePolicy {
    font-family    : Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width          : 100%;
}

#TableManagePolicy td,
#TableManagePolicy th {
    border : 1px solid #ddd;
    padding: 8px;
}

#TableManagePolicy tr:nth-child(even) {
    background-color: #f2f2f2;
}

#TableManagePolicy tr:hover {
    background-color: #ddd;
}

#TableManagePolicy th {
    padding-top     : 12px;
    padding-bottom  : 12px;
    background-color: #04aa6d;
    text-align      : left;
    color           : white;
}