@media only print and (max-width: 1200px) {
  @page {
    margin: 15px 0 0 0;
    padding: 0;
    size: A4;
  }

  body * {
    visibility: hidden;
  }

  #order,
  #order * {
    Table {
      width: 100%;
    }

    visibility: visible;

    font-size: 17px;

    h5,
    .total-value {
      font-size: 20px;
    }

    .col-6 {
      width: 40%;
    }

    .col-1 {
      width: 10%;
    }

    .col-2 {
      width: 20%;
    }

    .col-8 {
      width: 70%;
      
      span{
          font-weight:bold;
          text-transform: uppercase;
      }
    }

    .col-4 {
      width: 30%;
      text-align: center;
    }
    .col-12 {
      width: 100%;
    }

    .order-header {
      .content-left {
        width: 70%;

        .co-logo {
          width: 15%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .content-right {
        width: 30%;
      }
    }

    .export-info {
      width: 100%;
    }
    .cust-info {
      div {
        width: 100%;
      }

      .order-detail {
        td.blank {
          height: 20px;
        }
      }

      div.small {
        font-size: 14px;
      }
    }

    .caution {
      font-size: 14px;
    }
    div.text-primary.small {
      font-size: 14px;
    }
  }

  #order {
    // position: static;
    // margin-top: 0;
    width: 95%; /* Adjust as needed */
    height: auto;
    margin: auto;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .btn.btn-dark {
    display: none;
  }

  .order-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-left {
      width: 100%;
      display: flex;
      justify-content: center;

      .co-logo {
        width: 12%;
        height: 20%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .content-right {
      width: 100%;
      margin: 5px 0;
    }

    h5 {
      font-size: 10px;
      margin: 0;
    }

    h6 {
      font-size: 8px;
      margin: 0;
    }

    h4 {
      font-size: 12px;
      margin: 0;
    }
  }

  .export-info {
    .col-4 {
      text-align: center;
    }
    h5 {
      font-size: 10px;
      margin: 0;
    }

    div.small {
      font-size: 8px;
    }

    h6 {
      font-size: 8px;
      margin: 0;
    }
  }

  .cust-info {
    .col-4 {
      text-align: center;
    }
    h5 {
      font-size: 10px;
      margin: 0;
    }

    div.small {
      font-size: 8px;
      width: 100%;
    }

    .col-12 {
      font-size: 8px;
    }

    h6 {
      font-size: 8px;
      margin: 0;
    }

    .order-detail {
      font-size: 8px;

      .col-1 {
        width: 10%;
      }
      .col-2 {
        width: 20%;
      }
      .col-7 {
        width: 50%;
      }
    }

    .order-price {
      font-size: 8px;
    }
  }
}

@media (min-width: 1024px) {
  .content-left {
    width: 70%;

    .co-logo {
      width: 15%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .content-right {
    width: 30%;
  }

  .export-info {
    .col-4 {
      text-align: center;
    }

    .col-3 {
      text-align: center;
    }
  }

  .cust-info {
    .col-4 {
      text-align: center;
    }
  }

  .option {
    position: fixed;
    bottom: 10px;
    right: 25px;
    z-index: 1003;
  }
}
