@media (min-width: 320px) and (max-width: 576px) {
  .news-container {
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 10px;
      color: #354b9c;
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }
    h6 {
      font-size: 8px;
    }

    span {
      font-size: 8px;
      &:active {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    .news-nav {
      gap: 5%;
      .img-container {
        width: 25%;
        height: fit-content;
        background-color: white;

        img {
          width: fit-content;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .detail-container {
        width: 65%;
        height: fit-content;
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .news-container {
    h4 {
      font-size: 12px;
    }
    h5 {
      font-size: 10px;
      color: #354b9c;
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }
    h6 {
      font-size: 8px;
    }

    span {
      font-size: 8px;
      &:active {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    .news-nav {
      gap: 5%;
      .img-container {
        width: 25%;
        height: fit-content;
        background-color: white;

        img {
          width: fit-content;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .detail-container {
        width: 65%;
        height: fit-content;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .news-container {
    h4 {
      font-size: 14px;
    }
    h5 {
      font-size: 12px;
      font-weight: bold;
      color: #354b9c;
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }
    h6 {
      font-size: 10px;
    }

    span {
      font-size: 10px;
      &:active {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    .news-nav {
      gap: 2%;
      .img-container {
        width: 33%;
        height: fit-content;
        background-color: white;

        img {
          width: fit-content;
          height: 100%;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .detail-container {
        width: 65%;
        height: fit-content;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .news-container {
    h4 {
      font-size: 25px;
    }
    img {
      &:hover {
        cursor: pointer;
      }
    }

    h5 {
      color: #354b9c;
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    span {
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    .img-container {
      width: 25%;
      height: fit-content;
      background-color: white;

      img {
        width: fit-content;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .detail-container {
      width: 75%;
      height: fit-content;
    }
  }
}

@media (min-width: 1200px) {
  .news-container {
    h4 {
      font-size: 30px;
      margin: 10px 0 !important;
    }
    img {
      &:hover {
        cursor: pointer;
      }
    }

    h5 {
      color: #354b9c;
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    span {
      &:hover {
        cursor: pointer;
        filter: saturate(0.6);
      }
    }

    .img-container {
      width: 25%;
      height: fit-content;
      background-color: white;

      img {
        width: fit-content;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .detail-container {
      width: 75%;
      height: fit-content;
    }
  }
}
