@media (min-width: 320px) and (max-width: 576px) {
  .contact-button {
    width: 20%;
    height: 100%;
    gap: 5%;
    outline: none;
    border-radius: 10px;
    border: none;
    background-color: #354b9c;
    color: white;
    font-size: 10px;
    .order {
      display: none;
    }
  }

  .contact-order {
    height: fit-content;
    width: 100vw;
    border: 1px solid black;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: serif;

    .header {
      text-align: center;
      background-color: #354b9c;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 1%;

      .close {
        background-color: white;
        outline: none;
        border: none;
        font-size: 15px;
      }
    }

    .body {
      font-size: 8px;

      .first-row,
      .second-row,
      .third-row {
        margin-bottom: 2%;

        input {
          font-size: 8px;
        }

        span {
          color: red;
        }

        .button {
          width: 25%;
          height: 25%;
          outline: none;
          background-color: #354b9c;
          color: white;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767px) {
  .contact-button {
    width: 20%;
    height: 100%;
    gap: 5%;
    outline: none;
    border-radius: 10px;
    border: none;
    background-color: #354b9c;
    color: white;
    font-size: 10px;
    .order {
      display: none;
    }
  }

  .contact-order {
    height: fit-content;
    width: 100vw;
    border: 1px solid black;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: serif;

    .header {
      text-align: center;
      background-color: #354b9c;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 1%;

      .close {
        background-color: white;
        outline: none;
        border: none;
        font-size: 15px;
      }
    }

    .body {
      font-size: 8px;

      .first-row,
      .second-row,
      .third-row {
        margin-bottom: 2%;

        input {
          font-size: 8px;
        }

        span {
          color: red;
        }

        .button {
          width: 25%;
          height: 25%;
          outline: none;
          background-color: #354b9c;
          color: white;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .contact-button {
    width: 25%;
    height: 100%;
    gap: 5%;
    outline: none;
    border-radius: 10px;
    border: none;
    background-color: #354b9c;
    color: white;
    font-size: 10px;
  }

  .contact-order {
    height: fit-content;
    width: 100vw;
    border: 1px solid black;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: serif;

    .header {
      text-align: center;
      background-color: #354b9c;
      font-weight: bold;
      font-size: 10px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 1%;

      .close {
        background-color: white;
        outline: none;
        border: none;
        font-size: 15px;
      }
    }

    .body {
      font-size: 8px;

      .first-row,
      .second-row,
      .third-row {
        margin-bottom: 2%;

        input {
          font-size: 8px;
        }

        span {
          color: red;
        }

        .button {
          width: 25%;
          height: 25%;
          outline: none;
          background-color: #354b9c;
          color: white;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .contact-button {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5%;
    outline: none;
    border-radius: 10px;
    border: none;
    background-color: #354b9c;
    color: white;
    .order {
      font-size: 16px;
    }
    &:hover {
      opacity: 0.9;
      transition: all ease 0.5s;
    }
  }

  .contact-order {
    height: fit-content;
    width: 50em;
    border: 1px solid black;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: serif;

    .header {
      text-align: center;
      background-color: #354b9c;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
      color: white;
      margin-bottom: 1%;

      .close {
        background-color: white;
      }
    }

    .body {
      font-size: 16px;

      .first-row,
      .second-row,
      .third-row {
        margin-bottom: 2%;

        span {
          color: red;
        }

        .button {
          width: 25%;
          height: 30%;
          outline: none;
          background-color: #354b9c;
          color: white;
          border: none;
          border-radius: 10px;
        }
      }
    }
  }
}
