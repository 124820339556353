#TableManageStock {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#TableManageStock td,
#TableManageStock th {
  border: 1px solid #ddd;
  padding: 8px;
}

#TableManageStock tr:nth-child(even) {
  background-color: #f2f2f2;
}

#TableManageStock tr:hover {
  background-color: #ddd;
}

#TableManageStock th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #04aa6d;
  text-align: center;
  color: white;

  .typeSelector {
    border: none;
    outline: none;
    width: 120px;
    color: black;

    .valueContainer {
      background-color: #04aa6d;
    }
  }
}
