.manage-product-container {
  padding: 0 5px;

  .save-stock-product {
    color: #fff;
    background-color: #007bff;
    border-radius: 10px;
    display: block;
    outline: none;
    border: none;
    padding: 10px;
    margin: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  .create-stock-product {
    color: #000;
    background-color: #fbff00;
    border-radius: 10px;
    display: block;
    outline: none;
    border: none;
    padding: 10px;
    margin: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
}
